import capture1 from "../images/captures/modale-nouvelle-visite.png";
import partir_ecolo from "../images/captures/partir-ecolo.png";
import clef_verte from "../images/captures/clef-verte.png";

const styles = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
}

const Versions = () => {

    return(
        <div className="content-body body-versions content-header p-0 pb-5" style={styles}>
            <div className="content-versions mx-5">
                <h1 className="my-5">La liste des versions de Nomade</h1>

                <h2 className="border-bottom pb-2 mb-3">1.9.5 (23/07/2024) dédicacé à José Sébéloué membre de La Compagnie créole 🕺🏾</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Élargissement de la liste des équipements/services pouvant venir compenser l’absence de piscine pour les niveaux 4 et 5 Clés (grilles location et chambre), tels que : espace aménagé pour le sport, la détente ou aux jeux, services+++, titulaire d’un label « Environnement »...</li>
                    <li>Ajout du critère obligatoire « Trousse de secours » à partir de 3 clés (grille location)</li>
                    <li>Modification du critère « Sèche-linge » qui devient obligatoire pour tous les niveaux de confort à partir de 6 personnes, mais devient optionnel poursi présence de laverie gratuite, ou logement d’une capacité inférieur à 6 pers. ou situé en Outre-Mer. Tolérance en 3 Clés pour les villes thermales, stations (mer, montagne), et résidences avec présence d'une laverie commune et gratuite.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.9.4 (19/02/2024)</h2>
                <h3 className="fs-4">Modification grilles insolite</h3>
                <ul>
                    <li>Le critère 2.4 "Sanitaires" doit obligatoirement être renseigné.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.9.3 (31/10/2023)</h2>
                <h3 className="fs-4">Modification grilles location</h3>
                <ul>
                    <li>Le critère 3.0 "Studio" est coché à "Non" par défaut.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.9.2 (04/09/2023)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Grille chambre : modification titre 6.1 – Agencement</li>
                    <li>Thématique "Spa et Bien Être", modification 1er critère : Posséder 2 équipements de détente (accès gratuit) ou 1 équipement de détente (accès gratuit) et 1 prestation</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.9 (04/07/2023) dédicacée à Sophie du Puy de Dôme et Loire 😉</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Patch de correction sur la perte partielle de visites suite à la duplication de blocs.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.8 (26/06/2023)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Thématique écologique : ajout du label "EcoLabel" européen.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.7 (21/06/2023) dédicacée à Magali GENTY du PAS-DE-CALAIS</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Une correction a été faite sur la synchronisation automatique.</li>
                    <li>De plus dans l'édition des visites, un nouveau bouton "Enregistrer" vous permet de sauvegarder votre visite à tout moment vers le serveur indépendamment de l'automatisme.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.6 (16/05/2023) dédicacée à Kenneth Boulding :
                    Celui qui croit que la croissance peut être infinie dans un monde fini est soit un fou, soit un économiste.</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Certificat d'agrément : ajout de la reconnaissance "Partir écolo" si nos conditions sont remplies :<br />
                        <img src={partir_ecolo} style={{width: '360pt'}} alt="Partir écolo" /><br />
                    </li>
                    <li>Certificat d'agrément : ajout de la reconnaissance "Clef verte" si les justificatifs sont fournis :<br />
                        <img src={clef_verte} style={{width: '360pt'}} alt="Clef verte" /><br />
                    </li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Corrige un problème sur l'affichage de la fenêtre des thématiques</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.5 (18/04/2023) dédicacée à Héloïse du 22 😉</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Corrige un problème de synchronisation vers le serveur</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.4 (11/04/2023)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Location & chambres : révision du critère 2.1.0 (Prestations et équipements de loisirs)</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.3 (05/04/2023) </h2>
                <h3 className="fs-4">Correction</h3>
                <ul>
                    <li>Les critères optionnels des thématiques ne comptent plus dans la validation du thème.</li>
                </ul>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Tableau de bord : nouveau bloc "Bien sans agrément".</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.2 (31/03/2023) </h2>
                <h3 className="fs-4">Correction</h3>
                <ul>
                    <li>Validaton du label Clef verte.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8.2 (24/03/2023) </h2>
                <h3 className="fs-4">Correction</h3>
                <ul>
                    <li>Renforcement de l'opération de scellage.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.8 (14/03/2023) dédicacée à Jacques Prévert : <i>Quand les éboueurs font grève, les orduriers sont indignés 😁</i></h2>
                <h3 className="fs-4">Optimisation</h3>
                <ul>
                    <li>Lors de la création d'une visite, toutes les infos du technicien sont copiées dans la visite. Si celui-ci vient à être supprimé ou remplacé, la visite n'est plus impactée.</li>
                    <li>Les infos "qui a modifié" et "qui a scellé" sont supprimées car seul le créateur de la visite peut faire ces actions.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.7.5 (23/02/2023) dédicacée à Jean-Lou du 39 😉</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Correction sur l'affichage après scellage d'une visite.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.7.4 (14/02/2023) dédicacée à Catherine du 67/68 😉</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Correction sur l'affichage des clés.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.7.3 (10/02/2023)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Dossier compte rendu : les critères présentés prennent en considération la clé estimée.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.7.2 (09/02/2023)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Correction duplication des blocs.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.7.1 (09/02/2023) dédicacé à Gandhi : <i>Il faut apprendre à rester serein au milieu de l’activité et à être vibrant de vie à la retraite.</i></h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Meilleure gestion du bouton "Sceller".</li>
                    <li>Bouton "Sceller" : ajout de messages indicatifs.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.7.0 (07/02/2023)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Ajout de fichiers aux visites des Insolites :
                        <br />- vous pouvez maintenant sceller les visites des Insolites à condition d'y joindre un document d'attestation (en mode connecté).
                        <br />- la fédération est alertée de ce scellage et vérifie la conformité du document.</li>
                    <li>Ajout de fichiers aux thématiques liées à des labels :
                        <br />- pour "valider" un label dans un thématique, il est maintenant nécessaire d'y joindre un document d'attestation (en mode connecté) ainsi que de renseigner la date de fin.
                        <br />- à J-30 jours de la date de fin du label concerné, vous serez alerté par email afin de contacter le propriétaire et de remplacer l'attestation et de mettre à jour la date de fin.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.6.6 (27/12/2022) dédicacée à Elodie du 82/31 😉</h2>
                <h3 className="fs-4">Optimisation</h3>
                <ul>
                    <li>Un message en rouge indique si la visite n'a pas son bien chargé dans Nomade : <span className="badge rounded-pill bg-danger">Ce bien est absent de Nomade</span><br />
                    Cela vient principalement d'une de ces 2 raisons :
                        <br />- après une visite, le bien est passé en mode démo. Hors Nomade ne charge pas les bien de démo !
                        <br />- le bien a été supprimé dans l'extranet...</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.6.5 (16/12/2022) dédicacée à Juliette du 85 😉</h2>
                <h3 className="fs-4">Optimisation</h3>
                <ul>
                    <li>Les visites "spéciales pour thématiques" doivent pouvoir être modifiables.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.6.4 (14/12/2022) dédicacée à Caroline du 87 😉</h2>
                <h3 className="fs-4">Optimisation</h3>
                <ul>
                    <li>Si un bien dans Nomade n'est pas trouvé sur le serveur, une page d'erreur s'affiche :<br />
                        <i>Une erreur s'est produite</i><br />
                        <i>Le bien correspondant à cette visite n'est pas dans la base de données.</i>
                    </li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.6.3 (09/12/2022)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Amélioration du fonctionnement en mode déconnecté.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.6.2 (28/11/2022)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Correctif sur les droits de modification des visites depuis la page Biens.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.6.1 (14/11/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Lors d’une duplication, si des différences sont détectées avec la dernière version de la grille (ajout, modification et/ou suppression de critère(s)), à titre informatif, une fenêtre intermédiaire apparaîtra affichant les critères concernés.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.14 (08/11/2022) dédicacée à Mme Raimbeault du 49 😉</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Ajout d'une aide support Nomade</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Correctif sur le délais des droits de modification des visites scellées.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.13 (26/10/2022) dédicacée à Sébastien du 88 😉</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Liste des biens : pour chaque bien, ajout d'un bouton vers la dernière visite de labellisation ou contrôle (si existante).</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.12 (25/10/2022) dédicacée à Jean-Loup du 39 😉</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Correctif sur la synchronistion des visites créées hors connection.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.11 (22/10/2022) dédicacée à Elise du 68 😉</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Améliorations/consolidation de l'étape "sceller". Problème corrigé sur les visites créés en mode déconnecté puis directement scellé. <br />Merci à Elise pour son aide !</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.10 (18/10/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Dossier de compte rendu : le compte rendu et le certificat sont maintenant par défaut assignés au propriétaire. Si pas de propriétaire, alors la personne morale liée au bien (agence, centrale...).
                    </li>
                    <li>Liste visites : le type de contact est indiqué à coté de son nom (propriétaire, agence, etc...)</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.9 (15/10/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Nouveau type de visite spécial thématique. Permet de gérer les thématiques concernant des biens sans visites sur Nomade V2.
                        <ul>
                            <li>Pas de scellé ni de compte rendu.</li>
                            <li>Attention : dès la création de ce type de visite, tous les thèmes du bien sont réinitialisés !</li>
                            <li>Toutes mises à jour des thèmes actualisent en direct le bien.</li>
                        </ul>
                    </li>
                    <li>Fenêtre nouvelle visite : la liste des types de visites est contextualisé.
                        Par ex : pour un bien ayant déjà une visite de labellisation, ce type de visite n'est plus sélectionnable.<br />
                        <img src={capture1} style={{width: '360pt'}} alt="Capture d'écran" /><br />
                    </li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Sceller : renforcement de la procédure.</li>
                </ul>


                <h2 className="border-bottom pb-2 mb-3">1.5.8 (03/10/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Thématiques : fusion des 3 labels partenaires avec les thématiques :
                        <ul>
                            <li><i>"Clef verte"</i> avec <i>"Vacances écologiques"</i></li>
                            <li><i>"Bienvenue à la ferme"</i> avec <i>"Vacances à la ferme"</i></li>
                            <li><i>"Accueil Pêche"</i> avec <i>"Destination pêche"</i></li>
                        </ul>
                        Si le label est validé, le thème est automatiquement validé !
                    </li>
                    <li>Le thème <i>"Animaux bienvenus"</i> est retiré pour éviter un conflic avec le service <i>"Animaux acceptés"</i> / à renseigner dans l'extranet</li>
                    <li>Le thème <i>"Escapade en ville"</i> est retiré pour éviter un conflic avec la localisation de la commune de l'hébergement</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.7 (26/09/2022)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Tableau de bord : "Biens à revisiter" passé sur 5 ans dans le dashboard.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.6 (23/09/2022)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Thématiques : ordre d'affichage alphabétique avec les labels partenaires en tête de liste</li>
                </ul>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Clé estimé : si la clé calculée change, la clé estimée est réinitialisée à 0</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.5 (17/09/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Tableau de bord : les 5 dernières locations créées depuis l'extranet</li>
                    <li>Nouvelle thématique : "Au pied des pistes"</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.4 (09/09/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Refonte de l'objet "Clés estimées".</li>
                    <li>Refonte des enregistrements des commentaires : le bouton enregistrer sauvegarde simultanément les 2 commentaires (public et privé). Cette action ferme la fenêtre.</li>
                    <li>Fenêtres des commentaires : lors d'un clic ou d'un tap avec le doigt (en mode tablette) sur l'extérieur de la fenêtre, cela ne ferme pas le fenêtre (évite la perte des commentaires).</li>
                </ul>

                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Suggestion de clé supérieure corrigé.</li>
                    <li>Thème : l'ajout d'un commentaire ne fait plus disparaître le bouton "Valider" ou "Invalider"</li>
                    <li>Compte rendu : le type de visite est affiché dans le titre du compte rendu.</li>
                    <li>Compte rendu : pour les visites de conseils, la clé n'est plus affichée.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.3 (30/08/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Ajout du label "Cle verte" dans les thématiques</li>
                    <li>Grille thématique "A la ferme" dissociée du label "Bienvenue à la ferme"</li>
                    <li>Grille thématique "Pêche" dissociée du label "Accueil pêche"</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.2 (25/07/2022)</h2>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Critère 5.4.5 (Radiateur sèche-serviettes) : devient facultatif pour les départements 971,972,973,974,975,976,977,978,986,987,988.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.1 (25/07/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Duplication : la superficie n'est plus conservée.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.5.0 (19/07/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Duplication : la clé estimée n'est plus conservée.</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Compte rendu : meilleurs calcul des critères nécessaires à la clé supérieure.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.7 (29/06/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Lors du scellé : tous les thèmes du bien sont réinitialisés avec ceux de la visite. Donc ne pas oublier de les renseigner !</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.6 (24/06/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Compte rendu : ajout d'un bouton 'Retour' vers la page précédente</li>
                    <li>Compte rendu : ajout d'un bouton 'Voir' qui redirige vers la visite</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>La mise à jour des thèmes des biens se fait au moment du scellé des visites.</li>
                    <li>Ensuite, seules les visites scellées mettent à jour les thèmes des biens.</li>
                    <li>La gestion de la suppression des thèmes.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.5 (14/06/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Si la clé calculée dépasse 2, le bloc clé estimée est caché (impossble d'estimer au delà de 3 clés)</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Thème "Vacances à la ferme" : tous les critères deviennent obligatoire.</li>
                    <li>Gestion du genre du technicien ou de la technicienne dans le compte rendu.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.4 (07/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Les re-visites passent à 5 ans (quinquennales)</li>
                    <li>Les visites antérieures au 11 Mai 2022 ne sont plus duplicables (pour des raisons de grilles qui ont évolué jusqu'au 10/05/2022)</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.3 (31/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Ajout de la civilité du technicien (à renseigner depuis l'extranet ! ).</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.2 (20/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Les modifications faites par les administrateurs ne modifient plus les "dates de modification" ainsi que le "modifiée par".</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Ajustement des dates de visites entre Nomade et le serveur</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2.1 (10/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Thèmes : tous les techniciens du même territoire ont les droits de manupiler les thèmes de toutes les visites.</li>
                    <li>Liste des biens : possibilité de classer par "Commune"</li>
                    <li>Évolutions des critères de la grille Location</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.2 (09/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Compte rendu visite : nouvelle page listant les critères nécessaires à la clé supérieure.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.9 (06/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Aide à la saisie des critères : mise en avant en orange des critères pour atteindre le niveau de clé supérieur. Donc sur une nouvelle visite, les critères "obligatoires" sont en oranges car ils sont nécessaires pour le classement 1 clé.</li>
                    <li>Indication dans le sommaire du nombre de critères nécessaires pour le niveau supérieur.</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Mise à jour de l'état du thème lors de la validation forcée.</li>
                    <li>Erreur lors de la saisie d'un commentaire.</li>
                    <li>Conflit entre themes éditables et calcul du niveau de clés supérieur.</li>
                    <li>Ajustement de certains critères.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.8 (02/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Visite scellée : possibilité de gérer les thèmes</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.7 (02/05/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Page lites de biens : classement par date de visite croissante et fonctions de tri sur la liste</li>
                    <li>Tableau de bord : affichage des visites non scellées dans bloc biens</li>
                    <li>Visite PDF : affichage du bouton de téléchargement dans la vue pdf avec nom de fichier personnalisé.</li>
                </ul>
                <h3 className="fs-4">Correction</h3>
                <ul>
                    <li>Calcul des biens avec visites à refaire dans les six mois et les 4 ans.</li>
                    <li>Debug lors du défilement de la page visite</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.6 (28/04/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Compte rendu de visite : ajout des adresse supplémetaires pour le prorpiétaire, le bien et le technicien.</li>
                    <li>Nouveau thème "Cheval"</li>
                </ul>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Location 10.2.3-10.2.4 : sèche-linge si logement en capacité 2 personnes et / ou si situé en Outre-Mer</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.7 (29/04/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Thèmes : certains thèmes sont validables si le propriétaire possède le label, tous les autres thèmes doivent avoir les critères cochés.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.5 (15/04/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Edition visite : déplacement des blocs vers le haut lors de leur sélection dans le sommaire.</li>
                    <li>Edition visite : grossissement des puces du nombre de clés dans le sommaire.</li>
                    <li>Edition visite : limitation de la zone de clic sur les champs du formulaire au-delà du texte.</li>
                    <li>Protection contre le rechargement de la page : désactivée dans pages de connexions.</li>
                </ul>
                <h3 className="fs-4">Corrections</h3>
                <ul>
                    <li>Clés estimées qui revenaient à zéro au rechargement de la visite</li>
                    <li>Perte de visite : concernaient</li>
                    <li>Visite Sceller : renfort de la sauvegarde visite avant le scellé</li>
                </ul>


                <h2 className="border-bottom pb-2 mb-3">1.1.4 (08/04/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Les visites scellées peuvent être modifiées par l'auteur durant 3 mois suivant la date de création</li>
                    <li>Dans le DCR (compte rendu visite) le propriétaire affiché (si multiple) sera celui de contact réservation</li>
                    <li>Page en erreur : affichage du message d'erreur détaillé à renvoyer au support de Nomade</li>
                    <li>Page édition visite : correction dysfonctionnement sur le titre personnalisable des block lors de la duplication</li>
                    <li>Page liste visites : élargissement colonne ID</li>
                    <li>Correction date update lors de changement de type de visite</li>
                    <li>Correction user_uid quand enregistrement vers le serveur</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.3 (05/04/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction page blanche sur compte rendu : lorsque le bien n'a pas de département</li>
                    <li>Correction page blanche sur compte rendu : lorsque le bien a plusieurs propriétaires, celui en "contact réservation" sera affiché dans l'agrément</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.2 (31/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction page blanche sur liste visites : lorsque le technicien ayant créé une visite n'existe plus</li>
                    <li>Evolution : si la clé calculée dépasse 3, le bloc clé estimée est caché (impossble d'estimer au delà de 3 clés)</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.0 (31/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction consultation visite avec plusieurs propriétairs par biens</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.1.0 (29/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction suppression des blocs</li>
                    <li>Correction perte d'une visite créée hors ligne lors d'une re-connexion.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.19 (29/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction duplication des blocs</li>
                    <li>Synchronisation renforcée lors du chargement d'une nouvelle version de Nomade</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.17 (25/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction logo clé dans l'agrément</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.16 (24/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Gestion des propriétaires multiples</li>
                    <li>Ajustement de la validation des thèmes</li>
                    <li>Adaptation de l'agrément aux biens Insolites</li>
                    <li>Duplication de visite : rafraîchissement de la liste des visites</li>
                    <li>Complément des genres propriétaires</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.15 (24/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Refonte du générateur de compte rendu</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.14 (22/03/2022)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Grille chambre : faute</li>
                </ul>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Les visites vides ne doivent plus faire planter l'application lors de l'affichage du compte rendu !
                        Message "Visite vide..."</li>
                    <li>Compte rendu : mide en forme de l'entête</li>
                    <li>Compte rendu : ajout adresse et coordonnées du technicien</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.13 (17/03/2022)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Thème montagne</li>
                </ul>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Correction de la suppression des thèmes</li>
                    <li>Correction sur l'affiche doublé des visites</li>
                    <li>Message plus précis sur le bouton "Scellé"</li>
                    <li>Mise en forme de l'en-tête du compte rendu selon la charte graphique</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.12 (16/03/2022)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Révision complète de la grille HLL</li>
                    <li>Chambre : révision des critères spécifiques aux Dom-Tom</li>
                    <li>Location : révision des critères 7.4</li>
                </ul>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>En mode connecté : le boutton "Commercer la visite" reste vérouillé tant que le serveur n'a pas répondu avec un nouveau N° de visite.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.11 (11/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Exporter la visite en PDF depuis "Voir cette visite": Chrome -> Fichier -> Imprimer -> Destination : Enregistrer au format PDF</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.10 (10/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Duplication visite : mise à jour des dates et de l'utilisateur avec les données courantes.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.9 (09/03/2022)</h2>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Enregistrement de la visite à intervalles réguliers lors de l'édition de celle-ci.</li>
                    <li>Login avec e-mail devient insensible à la casse</li>
                </ul>

                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Chambre/Location -> ajout dans Bloc Extérieur -> "Stationnement si existant", "Espace extérieur si existant",</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.8 (01/03/2022)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Chambre/Location -> Bloc 2 "lave vaiselle", ajout d'un choix : "Absence de lave-vaiselle ou inférieur à 6 couverts"</li>
                    <li>Location -> Bloc 10 "Lave-linge", ajout d'un choix : "Lave-linge optionnel si logement en capacité 2 personnes"</li>
                    <li>Tous -> Bloc Extérieur -> signalitique : facultatif pour les visites de "Labellisation"</li>
                </ul>
                <h3 className="fs-4">Fonctionnalités</h3>
                <ul>
                    <li>Bouton sceller visite : désactivé si hors ligne.</li>
                    <li>Les administrateurs peuvent modififer n'importe quelle visite.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.7 (28/02/2022)</h2>
                <h3 className="fs-4">Modification grilles</h3>
                <ul>
                    <li>Chambre/Location -> Bloc 2 "Prestations et équipements de loisirs" : devient supprimable, pour les logements ou chambres sans équipements extérieur.</li>
                </ul>
                <h3 className="fs-4">Tableau de bord</h3>
                <ul>
                    <li>Le compte total de bien ne concerne que les actifs.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.6 (26/02/2022)</h2>
                <h3 className="fs-4">Modification des droits</h3>
                <ul>
                    <li>Une visite ne pas être modifiée/supprimée par un autre technicien.</li>
                </ul>
                <h3 className="fs-4">Nouvelle fonctionnalité sur grille</h3>
                <ul>
                    <li>Chambres/Location -> 1.1.1 rendu facultatif : "La signalétique..." </li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.5 (24/02/2022)</h2>
                <h3 className="fs-4">Nouvelle fonctionnalité sur grille</h3>
                <ul>
                    <li>Chambres -> possibilité de supprimer la chambre pour les studio</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.4 (24/02/2022)</h2>
                <h3 className="fs-4">Corrections grilles</h3>
                <ul>
                    <li>Chambres -> 4.2 : "Oreiller supplémentaire non obligatoire"</li>
                    <li>Chambres -> 6.2 rendu facultatif : "Si présent, lave-mains avec nécessaire..."</li>
                    <li>Location -> 7.4 : "Oreiller supplémentaire non obligatoire"</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.3 (22/02/2022)</h2>
                <h3 className="fs-4">Corrections grilles</h3>
                <ul>
                    <li>Chambres</li>
                    <li>Location</li>
                </ul>
                <h3 className="fs-4">Tableau de bord</h3>
                <ul>
                    <li>Ajout "Biens sans date de visite"</li>
                </ul>
                <h3 className="fs-4">Scellé</h3>
                <ul>
                    <li>Enregistrement de la clé et de la date visite dans le bien (visible depuis l'extranet)</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.2 (17/02/2022)</h2>
                <h3 className="fs-4">Correction grille chambre</h3>
                <ul>
                    <li>1.2 – Environnement</li>
                    <li>1.3 – Style architectural</li>
                    <li>6.3 – WC</li>
                    <li>8.2 – Ascenseur</li>
                </ul>
                <h3 className="fs-4">Correction grille location</h3>
                <ul>
                    <li>8.2 – Ascenseur</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.1 (15/02/2022)</h2>
                <h3 className="fs-4">Evolutions / corrections</h3>
                <ul>
                    <li>URLencodage des mots de passe</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">1.0.0 (14/02/2022)</h2>
                <h3 className="fs-4">Mise en production</h3>
                <h3 className="fs-4">Evolutions / corrections</h3>
                <ul>
                    <li>Tableau de bord : nouveau calcul des chiffres et liens vers départements</li>
                    <li>Evolutions grilles Location et HLL</li>
                    <li>Nettoyage code</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">0.1.6 (11/02/2022)</h2>
                <h3 className="fs-4">Evolutions / corrections</h3>
                <ul>
                    <li>Duplication de visite : scellé -> non scellé</li>
                    <li>Postes sous Windows : ajout des scrollbars</li>
                    <li>Diverses corrections sur les grilles</li>
                    <li>Tableau de bord : nouveau bloc filtré par départements</li>
                    <li>Radiogroup : déplacement aide</li>
                        <li>Schéma corrections</li>
                        <li>VisiteItem dans liste des visites : saut à la ligne pour les types de visite.</li>
                        <li>VisiteEdit : affichage des scrollbars sur les thématiques et le sommaire si sur Windows.</li>
                        <li>AppHeader : suppression code obsolète</li>
                        <li>Modif des intitulés</li>
                        <li>[FIX] Code défensif sur le calcul des clés</li>
                        <li>Aide sur les H3</li>
                        <li>FormElement : gestion des conditions pour les départements.</li>
                        <li>[WIP] test compatibilité</li>
                        <li>[FIX] État du bouton compte-rendu après modification ou suppression du compte-rendu.</li>
                        <li>[FIX] Tableau de bord : scroll dans les contenus quand écran trop court</li>
                        <li>Schéma loc : ascenseur facultatif</li>
                        <li>Check version problème</li>
                        <li>VisiteEdit : validation d'un thème</li>
                        <li>Prise en compte des conditions liées au département</li>
                        <li>[FIX] nouvelle url pour import thèmes</li>
                        <li>Duplication visite : même proprio et même type de bien.</li>
                        <li>Composant image avec url dynamique</li>
                        <li>Nouveau type "image"</li>
                        <li>Délais check version</li>
                        <li>Déplacement des Schémas dans src/ </li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">0.1.5 (09/02/2022)</h2>

                <h3 className="fs-4">Evolutions</h3>
                <ul>
                    <li>Possibilité de modification du type de visite</li>
                    <li>Tableau de bord : filtrage possible par département</li>
                    <li>Possibilité de dupliquer les visites</li>
                    <li>Mise en place d'un système d'alerte lors des publications des nouvelles versions de Nomade</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">0.1.6 (11/02/2022)</h2>

                <h2 className="border-bottom pb-2 mb-3">0.1.4 (03/02/2022)</h2>
                <h3 className="fs-4">Grilles visites</h3>
                <ul>
                    <li>Nombreux ajustement sur les critères</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">0.1.3 (03/02/2022)</h2>

                <h3 className="fs-4">Page liste des visites</h3>
                <ul>
                    <li>Par defaut, classement des visites uid le plus récent</li>
                </ul>
                <h3 className="fs-4">Grille location</h3>
                <ul>
                    <li>Piscine avec les équipements facultatifs</li>
                </ul>
                <h3 className="fs-4">Type de visites</h3>
                <ul>
                    <li>Renommage "Re-visite" en "Visite quadriennale"</li>
                    <li>Pour les visites de type "test" et "conseil", pas de certificat d'agrément.</li>
                </ul>
                <h3 className="fs-4">Dossier compte rendu de visite</h3>
                <ul>
                    <li>Ajout d'information supplémentaires</li>
                    <li>Changement de la mise en forme</li>
                </ul>
                <h3 className="fs-4">Page consulation d'une visite</h3>
                <ul>
                    <li>Seul les thèmes "validés" sont affichés</li>
                </ul>
                <h3 className="fs-4">Grille visite</h3>
                <ul>
                    <li>Correction des duplications de blocs</li>
                </ul>
                <h3 className="fs-4">Tableau de bord</h3>
                <ul>
                    <li>Correction des nombres</li>
                    <li>Ajout de la ligne "Propriétaires"</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">0.1.2 (31/01/2022)</h2>
                <h3 className="fs-4">Calcul des clés</h3>
                <ul>
                    <li>Révision complète du moteur de calcul des clés</li>
                </ul>
                <h3 className="fs-4">Visites</h3>
                <ul>
                    <li>Affichage des clés minimales pour chaque critère</li>
                    <li>Correction : la duplication d'une visite copie aussi le commentaire</li>
                </ul>
                <h3 className="fs-4">Aide</h3>
                <ul>
                    <li>Ajout infobulle sur les titres h1 et H2</li>
                </ul>
                <h3 className="fs-4">Insolite</h3>
                <ul>
                    <li>Masquage des clés et affichage en mode "validation" (identique aux thèmatiques)</li>
                </ul>
                <h3 className="fs-4">Thèmes</h3>
                <ul>
                    <li>Correction critères facultatifs</li>
                </ul>
                <h3 className="fs-4">Compte-rendu pdf</h3>
                <ul>
                    <li>Optimisation de l'affichage</li>
                </ul>
                <h3 className="fs-4">Tableau de bord</h3>
                <ul>
                    <li>Ajout des liens vers les pages pré-filtrées</li>
                </ul>


                <h2 className="border-bottom pb-2 mb-3">0.1.1 (24/01/2022)</h2>
                <h3 className="fs-4">Grille chambre d'hôtes</h3>
                <ul>
                    <li>Correction critères 4.3.1;5.2.1; </li>
                </ul>
                <h3 className="fs-4">Page liste des visites</h3>
                <ul>
                    <li>Infobulles au survol de la souris sur les boutons d'action des visites.</li>
                </ul>


                <h2 className="border-bottom pb-2 mb-3">0.1.1 (21/01/2022)</h2>
                <h3 className="fs-4">Visites archives</h3>
                <ul>
                    <li>Suppression des doublons</li>
                </ul>
                <h3 className="fs-4">Page liste des visites</h3>
                <ul>
                    <li>Infobulles au survol de la souris sur les boutons d'action des visites.</li>
                </ul>

                <h3 className="fs-4">Page Visite en mode lecture</h3>
                <ul>
                    <li>Affichage des commentaires saisis au niveau des blocs racine.</li>
                    <li>Affichage des éléments enfants des groupes de boutons radio et de cases à cocher.</li>
                    <li>Mise à jour des schémas avec ajout du type de bloc dans tous les cas.</li>
                    <li>Affichage des clés calculées au niveau des blocs racine.</li>
                </ul>

                <h3 className="fs-4">Page Visite en mode édition</h3>
                <ul>
                    <li>L'action sur les items du sommaire de la visite fonctionne désormais sur les éléments dupliqués.</li>
                    <li>Infobulles au survol de la souris sur les boutons duplication et suppression des blocs.</li>
                    <li>Infobulles au survol de la souris sur les boutons d'action de la visite.</li>
                    <li>Affichage des clés estimées à « 0 » quand aucune clé n'a encore été calculée.</li>
                </ul>

                <h3 className="fs-4">Page propriétaires</h3>
                <ul>
                    <li>Dans la fenêtre modale qui liste les biens d'un propriétaire, le bouton « Visiter » est désormais actif.</li>
                </ul>

                <h3 className="fs-4">Page biens</h3>
                <ul>
                    <li>Correction texte « Habitation légère de loisirs » avec un  « s » à loisirs.</li>
                </ul>

                <h3 className="fs-4">Identification</h3>
                <ul>
                    <li>Gestion des identifiants quand identifiants non-présents dans données reçues.</li>
                </ul>

                <h2 className="border-bottom pb-2 mb-3">0.1.0 (13/01/2022)</h2>
                <ul>
                    <li>Lancement de la version béta pour premiers tests.</li>
                </ul>
            </div>

        </div>
    );
}

export default Versions;
